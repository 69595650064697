import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../global.css'; // Import the global stylesheet
import './Profile.css'; 

const Profile = ({ user }) => {
  const [photoUrl, setPhotoUrl] = useState('');
  const [rank, setRank] = useState(null);

  useEffect(() => {
    const fetchRank = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/all-profiles`, { withCredentials: true });
        const allUsers = response.data;
        const sortedUsers = allUsers.sort((a, b) => b.rank - a.rank);
        const userRank = sortedUsers.findIndex(u => u._id === user._id) + 1;
        setRank(userRank);
      } catch (err) {
        console.error('Error fetching all profiles', err);
      }
    };

    fetchRank();
  }, [user._id]);

  const handlePhotoUrlChange = (e) => {
    setPhotoUrl(e.target.value);
  };

  const handlePhotoUrlSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/update-photo-url`, { photoUrl }, { withCredentials: true });
      alert('Profile photo URL updated successfully');
      console.log('Uploaded file URL:', response.data.url);
      // Optionally, you might want to update the user's photos array here
    } catch (err) {
      console.error('Error updating photo URL', err);
      alert('Failed to update photo URL');
    }
  };

  const tweetRank = () => {
    const tweetText = `I'm ranked #${rank} on Hottest of Crypto! Check it out: https://hottestofcrypto.xyz`;
    const twitterUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(tweetText)}`;
    window.open(twitterUrl, '_blank');
  };

  return (
    <div className="container">
      <h2>{user.username}'s Profile</h2>
      <img className="profile-picture" src={user.profilePicture} alt="Profile" />
      <form onSubmit={handlePhotoUrlSubmit}>
        <input 
          type="text" 
          placeholder="Enter profile photo URL" 
          value={photoUrl} 
          onChange={handlePhotoUrlChange} 
        />
        <button type="submit">Update Photo</button>
      </form>
      <div>
        <h3>Current Rank: #{rank !== null ? rank : 'Loading...'}</h3>
        <button onClick={tweetRank}>Tweet Your Rank</button>
      </div>
    </div>
  );
};

export default Profile;
