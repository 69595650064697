import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { PulseLoader } from 'react-spinners';
import './Voting.css';

const Voting = () => {
  const [profiles, setProfiles] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchProfiles();
  }, []);

  const fetchProfiles = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/random-profiles`, { withCredentials: true });
      if (Array.isArray(response.data)) {
        setProfiles(response.data);
      } else {
        console.error('API response is not an array:', response.data);
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching profiles:', error);
      setLoading(false);
    }
  };

  const handleVote = async (winnerId, loserId) => {
    try {
      await axios.post(`${process.env.REACT_APP_BACKEND_URL}/vote`, { winnerId, loserId }, { withCredentials: true });
      fetchProfiles(); // Fetch new profiles after voting
    } catch (error) {
      console.error('Error voting:', error);
    }
  };

  if (loading) {
    return (
      <div className="loader-container">
        <PulseLoader color="#36d7b7" />
      </div>
    );
  }

  if (profiles.length !== 2) {
    return (
      <div className="no-profiles">
        <h2>No profiles available for voting</h2>
      </div>
    );
  }

  return (
    <div className="voting-container">
      <h2>Who's Hotter?</h2>
      <div className="profiles">
        {profiles.map((profile, index) => (
          <div key={profile._id} className="profile" onClick={() => handleVote(profile._id, profiles[1 - index]._id)}>
            <img src={profile.profilePicture} alt="Profile" className="profile-picture" />
            <p className="profile-username">{profile.username}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Voting;
