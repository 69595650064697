import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Profile from './components/Profile';
import Voting from './components/Voting';
import Rankings from './components/Rankings';
import { PulseLoader } from 'react-spinners';
import './App.css';
import './global.css'; // Import the global stylesheet

const App = () => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/profile`, { withCredentials: true });
        const userData = response.data;
        if (!userData.photos) {
          userData.photos = [];
        }
        console.log('User data fetched:', userData);
        setUser(userData);
      } catch (error) {
        console.error('Error fetching profile:', error);
        console.log('Redirecting to login...');
        window.location.href = `${process.env.REACT_APP_BACKEND_URL}/auth/twitter`;
      }
    };

    fetchProfile();
  }, []);

  if (!user) {
    return (
      <div className="loader-container">
        <PulseLoader color="#36d7b7" />
      </div>
    );
  }

  return (
    <Router>
      <header>
        <h1>🔥 Hottest of Crypto 🔥</h1>
      </header>
      <div>
        <nav>
          <ul>
            <li><Link to="/">Profile</Link></li>
            <li><Link to="/voting">Voting</Link></li>
            <li><Link to="/rankings">Rankings</Link></li>
          </ul>
        </nav>
        <Routes>
          <Route path="/" element={<Profile user={user} />} />
          <Route path="/voting" element={<Voting />} />
          <Route path="/rankings" element={<Rankings />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
